import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideRouter } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { hardNavigateMiddleware } from '@presentation/shared/router';
import { SHIPPING_INFO_URL } from '../../constants';
import { SafePipe } from '../../pipes/safe.pipe';
import { ShippingInfoComponent } from './shipping-info.component';

@NgModule({
  imports: [CommonModule, TranslateModule, ShippingInfoComponent, SafePipe],
  providers: [
    provideRouter([
      {
        path: '',
        component: ShippingInfoComponent,
        canActivate: [hardNavigateMiddleware(SHIPPING_INFO_URL)],
      },
    ]),
  ],
})
export class ShippingInfoModule {}
